import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import Nav from '../components/nav'
import Faq from '../components/faq'
import Footer from '../components/footer'

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
			<Nav />
            <div className="layout">
				<div className="layout__wide padding__large--top">
					<div className="flex flex__space-between">
						<div className="col__80 body">
							<h1>{ entry.frontmatter.heading }</h1>
						</div>
					</div>
				</div>
            </div>
            <main className="layout padding__large">
				<div className="layout__wide">
					<Faq faqs={entry.frontmatter} />
				</div>
            </main>
			<Footer />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				page_title
				meta_description
				heading
                faq {
                    question
                    answer
                }
			}
			fields {
				slug
			}
			html
		}
	}
`